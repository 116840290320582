<template>
  <div class="check-valuation">
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      class="d-flex justify-content-center align-items-center"
      no-wrap
    >
      <b-spinner
        variant="light"
        label="Loading..."
      />
    </b-overlay>

    <KTCodePreview title="Kiểm tra định giá">
      <template #preview>
        <div class="row">
          <div class="col-md-8">
            <ProductSearch
              :selectTypeSearch="selectTypeSearch"
              :listTypeSearch="listTypeSearch"
              v-model="searchProductFor"
              :options="autosuggestOptions"
              @type-change="changePlaceholder"
              @product-select="onSelectedProductFor"
              @input-change="onInputChangeProductFor"
            />

            <div
              v-if="!hasValuationOptions"
              class="alert alert-secondary text-center py-4"
            >
              <i
                class="fa fa-exclamation-circle text-warning"
                aria-hidden="true"
              ></i>
              <span class="ml-2">Chưa có thông tin định giá. Vui lòng nhập sản phẩm!</span>
            </div>

            <ValuationTable
              v-for="(group) in valuationRequest.listGroupOption"
              :key="group.id"
              :group="group"
              :finalBuyingPrice="finalBuyingPriceProp"
              @radio-change="handleRadioChange"
            />
          </div>

          <div class="col-md-4">
            <OverviewSidebar
              :product="selectedProduct"
              :valuationRequest="valuationRequest"
              :finalBuyingPrice="finalBuyingPrice"
              :totalCriteriaPrice="totalCriteriaPrice"
            />
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice } from '@/utils/common';
import {
  TIME_TRIGGER,
  VALUATION_REQUEST_STATUS,
  VALUATION_REQUEST_STATUS_OPTION,
} from '@/utils/constants';
import { getToastInstance } from '@/utils/toastHelper';
import KTCodePreview from '@/view/content/CodePreview.vue';
import UpdateValuationRequestReqModel from '@/view/pages/valuation-request/models/UpdateValuationRequestReqModel';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import toNumber from 'lodash/toNumber';
import { validationMixin } from 'vuelidate';

import ValuationTable from './CheckValuationTable.vue';
import OverviewSidebar from './OverviewSidebar.vue';
import ProductSearch from './ProductSearch.vue';

export default {
  name: 'CheckValuation',
  mixins: [validationMixin],
  components: {
    KTCodePreview,
    ProductSearch,
    ValuationTable,
    OverviewSidebar,
  },
  data() {
    return {
      isLoading: false,
      valuationRequest: {
        searchTradeInProgram: '',
        searchProduct: '',
        searchCustomer: '',
        customerId: null,
        searchStore: '',
        searchImei: '',
        selectedStatus: 1,
        createdBy: null,
        createdAt: null,
        listGroupOption: [],
        productBuyingPrice: 0,
        totalCriteriaPrice: 0,
        estimationBuydingPrice: 0,
        finalBuyingPrice: 0,
        note: '',
        cancelNote: '',
      },
      form: {
        username: '',
        password: '',
      },
      selectedEmployee: null,
      VALUATION_REQUEST_STATUS_OPTION,
      VALUATION_REQUEST_STATUS,
      selectedRole: null,
      listRole: [],
      selectedId: null,
      isNew: true,
      searchEmployee: '',
      filteredOptions: [],
      listTradeInProgram: [
        { id: 1, name: 'AAA' },
        { id: 2, name: 'BBBB' },
      ],
      selectedTradeInProgram: 1,
      filteredProducts: [],
      selectedProduct: {},
      selectTypeSearch: 1,
      searchProductFor: '',
      listTypeSearch: [{ id: 1, name: 'Tìm sản phẩm imei' }],
      storeOptions: [],
      filteredStoreOptions: [],
      finalBuyingPriceProp: 0,
    };
  },
  computed: {
    valuationRequestId() {
      return this.$route.query.id;
    },
    hasValuationOptions() {
      return (
        this.valuationRequest.listGroupOption &&
        this.valuationRequest.listGroupOption.length
      );
    },
    autosuggestOptions() {
      return cloneDeep(this.filteredProducts);
    },
    totalCriteriaPrice() {
      if (
        !this.valuationRequest ||
        !Array.isArray(this.valuationRequest.listGroupOption)
      ) {
        return 0;
      }

      return this.valuationRequest.listGroupOption.reduce((total, group) => {
        if (!group.listItem || !Array.isArray(group.listItem)) {
          return total;
        }
        group.listItem.forEach((item) => {
          if (item.selected) {
            total += item.operatorType === 1 ? item.amount : -item.amount;
          }
        });
        return total;
      }, 0);
    },
    finalBuyingPrice() {
      const total =
        toNumber(this.valuationRequest.productBuyingPrice) +
        toNumber(this.totalCriteriaPrice);
      return total > 0 ? total : 0;
    },
  },
  validations: {
    form: {},
  },
  async created() {
    this.tradeInId = this.$route.query.id;
    await this.onInitData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Kiểm tra định giá' }]);
  },
  methods: {
    ...getToastInstance(),
    async onInitData() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const stores = await this.fetchStore();
        this.storeOptions = stores.map((store) => ({
          id: store.id,
          name: store.name,
          shortName: store.shortName,
          suggestionName: `${store.shortName} (${store.name})`,
        }));
        this.filteredStoreOptions = [...this.storeOptions];
      } catch (error) {
        this.makeToastFailure(
          error.message || 'Có vấn đề xảy ra. Vui lòng thử lại.',
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStore() {
      const response = await ApiService.get('/stores/getStores');
      return response.data.data;
    },
    handleRadioChange(selectedGroupOption, selectedGroupOptionItem) {
      if (!this.valuationRequest.listGroupOption) return;

      const updatedListGroupOption = this.valuationRequest.listGroupOption.map(
        (groupOption) => {
          if (groupOption.id !== selectedGroupOption.id) return groupOption;

          const updatedListItem = groupOption.listItem.map((item) => {
            if (item.id !== selectedGroupOptionItem.id)
              return {
                ...item,
                selected: false,
              };

            return {
              ...item,
              selected: item.selected ? false : item.id,
            };
          });
          return {
            ...groupOption,
            listItem: updatedListItem,
          };
        },
      );
      this.valuationRequest.listGroupOption = updatedListGroupOption;
    },
    async onSelectedProductFor(option) {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const { id, productName, productType, imeiNo } = option.item;
        const response = await ApiService.query(
          '/tradeInProgram/mobile/by-product-id',
          {
            params: { productId: id },
          },
        );

        const data = response.data.data;
        this.selectedProduct = {
          id: id,
          productId: id,
          productName,
          quantity: 1,
          productBuyingPrice: data.buyingPrice,
          productType,
          imeiNo,
        };

        this.valuationRequest = {
          ...this.valuationRequest,
          productBuyingPrice: data.buyingPrice,
          totalCriteriaPrice: data.totalCriteriaPrice,
          estimationBuydingPrice: data.estimationBuydingPrice,
          finalBuyingPrice: data.finalBuyingPrice,
          tradeInProgramName: data.name,
          tradeInProgramId: data.id,
          searchTradeInProgram: data.name,
          listGroupOption: data.tradeInProgramCriteriaGroup.map((group) => ({
            id: group.id,
            priority: group.priority,
            criterialName: group.criteriaGroupName,
            type: group.type,
            listItem: group.tradeInProgramCreteriaGroupOptions.map(
              (option) => ({
                id: option.id,
                priority: option.priority,
                name: option.creteriaName,
                operatorType: option.operator,
                amount: option.amount,
                description: option.description,
                selected: false,
                optionType: group.type,
              }),
            ),
          })),
        };

        this.finalBuyingPriceProp = data.buyingPrice,      
        this.makeToastSuccess('Lấy thông tin định giá thành công');

        this.searchProductFor = '';
        this.filteredProducts = [];
      } catch (error) {
        if (error.response) {
          this.makeToastFailure(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          this.makeToastFailure(
            error.message || 'Có vấn đề xảy ra hãy thử lại',
          );
        }
      } finally {
        this.$nextTick(() => {
          this.isLoading = false;
        });
      }
    },
    changePlaceholder() {
      const placeholderMap = {
        1: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        2: 'Tìm kiếm theo mã IMEI sản phẩm',
      };
      if (
        this.$refs.productSearch &&
        typeof this.$refs.productSearch.setPlaceholder === 'function'
      ) {
        this.$refs.productSearch.setPlaceholder(
          placeholderMap[this.selectTypeSearch] || '',
        );
      }
      this.filteredProducts = [];
    },
    async onSubmit() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        const criteriaGroups = this.getSelectedCriteriaGroups();
        const data = new UpdateValuationRequestReqModel({
          ...this.valuationRequest,
          id: this.valuationRequestId,
          productId: this.selectedProduct.productId,
          imeiNo: this.selectedProduct.imeiNo,
          criteriaGroups,
          status: this.valuationRequest.selectedStatus,
        });

        const res = await ApiService.put('valuation-requests', data);
        this.makeToastSuccess(res.data.message);
        this.$router.push({ name: 'list-valuation-request' });
      } catch (error) {
        const message =
          error.response?.data?.message || 'Có vấn đề xảy ra. Hãy thử lại.';
        this.makeToastFailure(message);
      } finally {
        this.isLoading = false;
      }
    },
    getSelectedCriteriaGroups() {
      return this.valuationRequest.listGroupOption.flatMap((group) =>
        group.listItem
          .filter((item) => item.selected)
          .map((item) => ({
            id: item.id,
            tradeInProgramCreteriaGroupId: group.id,
            creteriaName: item.name,
            description: item.description,
            priority: item.priority,
            operator: item.operatorType,
            amount: item.amount,
          })),
      );
    },
    convertPrice,
    fetchProduct: debounce(async function (textSearch) {
      this.productLoading = true;
      try {
        const params = { productName: textSearch.trim() || null };
        const response = await ApiService.query(
          '/tradeInProgram/mobile/by-product-name',
          { params },
        );
        this.filteredProducts = response.data.data;
      } catch (error) {
        this.makeToastFailure(error.message || 'Không thể tìm kiếm sản phẩm.');
      } finally {
        this.productLoading = false;
      }
    }, TIME_TRIGGER),
    onInputChangeProductFor(text) {
      if (!text) {
        this.productLoading = false;
        this.filteredProducts = [];
        return;
      }
      this.searchProductFor = text.trim();
      this.fetchProduct(this.searchProductFor);
    },
  },
  watch: {
    totalCriteriaPrice(newVal) {
      this.valuationRequest.totalCriteriaPrice = newVal;
    },
    finalBuyingPrice(newVal) {
      this.valuationRequest.finalBuyingPrice = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.bootstrap.scss';

.check-valuation {
  .alert-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashed {
    border-bottom: 1px dashed #ccc;
    margin: 1rem 0;
  }

  .custom-checkbox .custom-control-label::before {
    border-radius: 0;
  }
}
</style>
